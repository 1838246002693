function Loading() {
  return (
    <div className="loading">
      <div className="loading__wrapper">
        <span className="loading__bubble loading__bubble0"></span>
        <span className="loading__bubble loading__bubble1"></span>
        <span className="loading__bubble loading__bubble2"></span>
      </div>
    </div>
  )
}

export default Loading
