import './Footer.css';
import { CODEPEN, FREECODECAMP, GITHUB, LASTUPDATED, LINKEDIN, WHATSAPP, EMAIL } from '../../library/Constants';
import ArrowLink from '../ArrowLink/ArrowLink';
import { useGSAP } from '@gsap/react';
import gsap from 'gsap';

function Footer() {
  useGSAP(() => {
    gsap.fromTo(".footer", {
      opacity: 0
    }, {
      opacity: 1,
      duration: 2
    })
  }, [])

  return (
    <div className="footer">
      <div className='footer__text'>
        <text>This website was designed and coded by <a href={LINKEDIN} target='blank' className='mint'>Tim Geenen</a></text>
        <text>Last updated: {LASTUPDATED}</text>
      </div>
      <div className='footer__links'>
        <div className='footer__linksWrapper' >
          <ArrowLink text="LinkedIn" url={LINKEDIN} />
          <ArrowLink text="WhatsApp" url={WHATSAPP} />
          <ArrowLink text="Email" url={EMAIL} />
        </div>
        <div className='footer__linksWrapper'>
          <ArrowLink text="GitHub" url={GITHUB} />
          <ArrowLink text="CodePen" url={CODEPEN} />
          <ArrowLink text="FreeCodeCamp" url={FREECODECAMP} />
        </div>
      </div>
    </div>
  )
}

export default Footer
