import './App.css';
import {
  Route,
  Routes,
  BrowserRouter,
} from "react-router-dom";
import Home from './Home';
import ScrollToTop from './library/ScrollToTop';
import React, { Suspense } from 'react';
import Loading from './components/Loading/Loading';

const Projects = React.lazy(() => import("./Projects"));
const About = React.lazy(() => import("./About"));
const Contact = React.lazy(() => import("./Contact"));
const Frontpage = React.lazy(() => import("./Frontpage"));

function App() {
  return (
    <BrowserRouter>
        <ScrollToTop />
        <Routes >
          <Route path="/" element={<Home />} >
            <Route index element={
              <Suspense fallback={<Loading />}>
                <Frontpage />
              </Suspense>
            } />
            <Route path="Frontpage" element={
              <Suspense fallback={<Loading />}>
                <Frontpage />
              </Suspense>
            } />
            <Route path="Projects" element={
              <Suspense fallback={<Loading />}>
                <Projects />
              </Suspense>
              } />
            <Route path="About" element={
              <Suspense fallback={<Loading />}>
                <About />
              </Suspense>
              } />
            <Route path="Contact" element={
              <Suspense fallback={<Loading />}>
                <Contact />
              </Suspense>
              } />
          </Route>
        </Routes>
    </BrowserRouter>
  );
}

export default App;
