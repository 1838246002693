import { useState } from "react"
import "./Header.css"
import { useLocation, useNavigate } from "react-router-dom";
import { gsap } from "gsap";
import { useGSAP } from "@gsap/react";    
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(useGSAP,ScrollTrigger);

function Header() {
  useGSAP(() => {
    gsap.fromTo(".header", {
      opacity: 0
    }, {
      opacity: 1,
      duration: 2
    })
    gsap.timeline({scrollTrigger: {
      trigger: ".header",
      endTrigger: ".footer",
      start: '+5px',
      end: ".footer",
      toggleActions: "play none none reverse"
    }})
    .to(".header__name", {opacity: 0}, .5)
    .to(".header__navigation", { 
      width: 384, 
      height: 64,
      marginTop: "1em",
      borderRadius: 32,
      boxShadow: "0 0 10px -5px var(--mint)"
    }, .5)
  }, []);

  const location = useLocation().pathname;
  const [active, setActive] = useState(location);

  return (
    <div className="header">
      <h3 className="header__name">TIM GEENEN</h3>
      <div className="header__navigation">
        <NavButton text={"Home"} active={active} setActive={setActive}/>
        <NavButton text={"Projects"} active={active} setActive={setActive}/>
        <NavButton text={"About"} active={active} setActive={setActive}/>
        <NavButton text={"Contact"} active={active} setActive={setActive}/>
      </div>
    </div>
  )
}

function NavButton({text, active, setActive}) {
    const navigate = useNavigate();
    let route = text === "Home" ? `/` : `/${text}`;

    const handleClick = () => {
        if (route !== active) {
            setActive(route);
            navigate(route);
        }
    }
    const navLinkStyles = {
        color: route === active && "var(--mint)"
      }
    
      const navLineStyles = {
        width: route === active ? "100%" : "0%"
    }
    
    return (
      <div className="nav__linksContainer">
        <span className="nav__line" style={navLineStyles}></span>
        <a 
        id={"nav__" + text} 
        className="nav__link"
        onClick={(e) => {handleClick()}}
        style={navLinkStyles}
        >{text}
        </a>
      </div>
    )
  }

export default Header