export const LASTUPDATED = "30 October 2024";
export const LINKEDIN = "https://www.linkedin.com/in/tim-geenen-62959a302/";
export const WHATSAPP = "https://wa.me/+31629234578";
export const EMAIL = "mailto:geenencleaning@gmail.com";
export const GITHUB = "https://github.com/Timgeenen";
export const CODEPEN = "https://codepen.io/Geenen";
export const FREECODECAMP = "https://www.freecodecamp.org/fcc57f784ce-2e62-4bc7-acb9-6d5f185a3375";
export const PROJECTS = [
    {
        title: 'TASK MANAGER',
        image: './task manager.png',
        url: 'https://sienna-ape-700339.hostingersite.com',
        text: "React and Redux task manager app with team collaboration, real-time chat, notifications, task assignments, and MongoDB for data storage.",
        tech: [
            {
                name: "React",
                icon: "./react_logo.png"
            }, {
                name: "Node.js",
                icon: "./node_logo.png"
            }, {
                name: "Redux",
                icon: "./redux_logo.png"
            }, {
                name: "MongoDB",
                icon: "./mongodb_logo.png"
            }, {
                name: "Tailwind",
                icon: "./tailwind_logo.png"
            }, {
                name: "Socket.IO",
                icon: "./socketio_logo.png"
            }, {
                name: "CSS",
                icon: "./css_logo.png"
            }, {
                name: "HTML",
                icon: "./html_logo.png"
            }, {
                name: "JavaScript",
                icon: "./javascript_logo.png"
            }, {
                name: "JWT",
                icon: "./json_token_logo.png"
            }
        ]
    }, {
        title: 'PORTFOLIO',
        image: './portfolio rica.png',
        url: 'https://japanesewithrica.com',
        text: 'Portfolio website created with Wordpress and Elementor, for native Japanese teacher Rica',
        tech: [
            {
                name: "WordPress",
                icon: "./wordpress_logo.png"
            }, {
                name: "Elementor",
                icon: "./elementor_logo.png"
            }, {
                name: "Figma",
                icon: "./figma_logo.png"
            }, {
                name: "CSS",
                icon: "./css_logo.png"
            }
        ]
    }, {
        title: 'AMAZON CLONE',
        image: './amazon_clone.png',
        url: 'https://clone-fd8f3.web.app/',
        text: "Amazon clone built with React, Redux, Firebase, and Stripe, featuring product listings, cart, secure authentication, and seamless payment processing.",
        tech: [
            {
                name: "React",
                icon: "./react_logo.png"
            }, {
                name: "Firebase",
                icon: "./firebase_logo.png"
            }, {
                name: "Redux",
                icon: "./redux_logo.png"
            }, {
                name: "CSS",
                icon: "./css_logo.png"
            }, {
                name: "HTML",
                icon: "./html_logo.png"
            }, {
                name: "JavaScript",
                icon: "./javascript_logo.png"
            }
        ]
    }, {
        title: 'PORTFOLIO',
        image: './my portfolio.png',
        url: 'https://timgeenen.com',
        text: "Personal web portfolio built with React, showcasing projects, skills, and experience in web development with an interactive and modern design.",
        tech: [
            {
                name: "React",
                icon: "./react_logo.png"
            }, {
                name: "CSS",
                icon: "./css_logo.png"
            }, {
                name: "HTML",
                icon: "./html_logo.png"
            }, {
                name: "JavaScript",
                icon: "./javascript_logo.png"
            }, {
                name: "Figma",
                icon: "./figma_logo.png"
            }, {
                name: "GSAP",
                icon: "./gsap_logo.png"
            }
        ]
    }
];
export const RANDOMFACTS = [
    " doesn't eat cheese unless it's on top of pizza",
    " has 2 brothers",
    " is a middle child",
    " ate a fried scorpion once",
    " has 100% winrate in his Muay Thai career (1W/0L)",
    " takes cold showers",
    " enjoys taking ice baths",
    " played volleyball for 10 years",
    "'s favorite anime is Demon Slayer",
    " did a silent retreat at a Thai monastery for 10 days",
    "'s horoscope is Libra",
    "'s Chinese horoscope is Wood Pig",
    " only drinks black coffee"
]
export const ABOUTPARAGRAPH = "I was born and raised in Helden, Limburg in the Netherlands and currently based in Chiang Mai. After three fulfilling years running my own window cleaning business, GeenenCleaning, I decided to embark on a new adventure. My journey took me across various countries in Southeast Asia and eventually led me to discover a passion for programming."
export const HOBBIES = [
    {
        title: "MUAY THAI",
        image: "./Muay Thai.gif",
    }, {
        title: "TRAVELLING",
        image: "./travelling.gif",
    }, {
        title: "ANIME",
        image: "./anime.gif",
    }, {
        title: "GAMING",
        image: "./gaming.gif",
    }, {
        title: "MOTORBIKE",
        image: "./motorbike_image.jpeg",
    }
]
export const DESIGN = [
    {
        name: "Figma",
        image: './figma_logo.png'
    }, {
        name: "Spline",
        image: "./spline_logo.png"
    }, {
        name: "GSAP",
        image: "./gsap_logo.png"
    }, {
        name: "WordPress",
        image: "./wordpress_logo.png"
    }, {
        name: "Tailwind",
        image: "./tailwind_logo.png"
    }, {
        name: "CSS",
        image: "./css_logo.png"
    }, {
        name: "Elementor",
        image: "./elementor_logo.png"
    }
]
export const CODING = [
    {
        name: "React",
        image: "./react_logo.png"
    }, {
        name: "JavaScript",
        image: "./javascript_logo.png"
    }, {
        name: "HTML",
        image: "./html_logo.png"
    }, {
        name: 'Node.js',
        image: "./node_logo.png"
    }, {
        name: "Python",
        image: "./python_logo.png"
    }, {
        name: "MongoDB",
        image: "./mongodb_logo.png"
    }, {
        name: "PostgreSQL",
        image: "postgresql_logo.png"
    }, {
        name: "Firebase",
        image: "./firebase_logo.png"
    }, {
        name: "Socket.IO",
        image: "./socketio_logo.png"
    }, {
        name: "JWT",
        image: "./json_token_logo.png"
    }, {
        name: "Redux",
        image: "./redux_logo.png"
    }
]