import './ArrowLink.css';
import EastIcon from '@mui/icons-material/East';


function ArrowLink({text, url, size}) {
  const arrowStyle = {
    fontSize: size * 1.25,
    marginLeft: size / 10,
    scale: "unset"
  }
  const textStyle = {
    fontSize: size
  }
  return (
    <span className='arrowLink' >
        <a href={url} target='blank' style={size && textStyle} >{text}
            <EastIcon className='arrowLink__arrow' style={size && arrowStyle}/>
        </a>
    </span>
  )
}

export default ArrowLink
